import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';


export default function EventosUsuarios() {
  const [event, setEvent] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [csv, setCsv] = useState(false)
  

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  function downloadCSV() {
    const fields = ['userName', 'userTel', 'userBirth', 'userEmail'];
    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(users.map(user => ({
      userName: `${user.userName} ${user.userLastname}`,
      userBirth: new Date(user.userBirth).toLocaleDateString("pt-BR"),
      userEmail: user.userEmail,
    })));
    
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${event.name}_event_users.csv`);
  }

  async function onLoad() {
    const resEvents = await api.get("events/" + params.id);
    setEvent(resEvents.data);

    const resUsers = await api.get("historyEvents/event/" + params.id);
    setUsers(resUsers.data);
    setLoading(false);
  }
  if (loading) {
    return (
      <div>
        Carregando...
        <div className="text-center">
          <img src="https://i.gifer.com/ZKZg.gif"  width={100} alt="loading"/>
        </div>
      </div>
    );
  }
  return (
    <div>
      <h1>{event.name}</h1>
      <h3>{users.length} Pessoas compareceram a esse evento</h3>
      <div className="my-3">
        <button  onClick={() => setCsv(false)} className="btn btn-primary">Legível</button> | <button onClick={() => setCsv(true)}  className="btn btn-warning">CSV</button> 
        | <button onClick={downloadCSV} className="btn btn-success ml-2">Download CSV</button>
      </div>
      <div>
        { csv ? (
          <div>
            {users.map((e) => (
              <span key={e.id}>
                {e.userName} {e.userLastname},
                {e.userTel},
                {new Date(e.userBirth).toLocaleDateString("pt-BR")},
                {e.userEmail}
              </span>
            ))}
          </div>
        ) : (
          <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Telefone</th>
              <th scope="col">Nascimento</th>
              <th scope="col">Email</th>
            </tr>
          </thead>
          <tbody>
            {users.map((e) => (
              <tr key={e.id}>
                <th scope="row">
                  <a href={"/usuario/" + e.userId}>
                    {e.userName} {e.userLastname}
                  </a>
                </th>
                <td>{e.userTel}</td>
                <td>{new Date(e.userBirth).toLocaleDateString("pt-BR")}</td>
                <td>{e.userEmail}</td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
        
      </div>
    </div>
  );
}
